import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div id={'main'} className={'container'}>
          {/* <h2>
            Latest Stories
          </h2>
          <section>
            <BlogRoll />
          </section> */}
          {/* generate coming soon section */}
          <h2>
            Coming Soon
          </h2>
          <section>
            <p>Check back soon for more stories.</p>
          </section>
        </div>
      </Layout>
    );
  }
}